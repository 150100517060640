export default [
  {
    name: "A: 2 ranged weapons & 2 melee weapons",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Ranged weapon 1",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 4,
                id: 1,
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                nameUnique: "Ranged weapon 1",
              },
              {
                name: "Ranged weapon 2",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 1,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon 2",
              },
              {
                name: "Melee weapon 1",
                type: "Melee",
                attacks: 4,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 3,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon 1",
              },
              {
                name: "Melee weapon 2",
                type: "Melee",
                attacks: 2,
                strength: 5,
                ap: -1,
                damage: 1,
                id: 4,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon 2",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [3, 4],
      ranged: [1, 2],
    },
  },
  {
    name: "A: 40 bolters @ BS2",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 40,
                id: 1,
                bs: 2,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                nameUnique: "Bolter",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: 20 bolters @ BS3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: 1,
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                nameUnique: "Bolter",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: 20 bolters @ BS6",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: 1,
                bs: 6,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                nameUnique: "Bolter",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: 10 bolters @ BS3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      points: 150,
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                nameUnique: "Bolter",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: 150,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: 10 heavy bolters @ BS3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      points: 320,
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Heavy bolter",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: -1,
                damage: 2,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Heavy bolter",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: 320,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: 10 3 shot Strength 3 guns @ BS3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: Weapon ability: ANTI AIR",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Weapon with ANTI AIR",
                type: "Ranged",
                attacks: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                abilities: [
                  {
                    description: "+1 to hit roll (AIRCRAFT only)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          keywords: ["AIRCRAFT"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: 1,
                      },
                      text: "Modify (relative)",
                      type: "modifyRelative",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Weapon with ANTI AIR",
              },
              {
                name: "Weapon with ANTI AIR:LEGACY",
                type: "Ranged",
                attacks: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                abilities: [
                  {
                    description: "+1 to hit roll (FLY only)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          keywords: ["FLY"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: 1,
                      },
                      text: "Modify (relative)",
                      type: "modifyRelative",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "-1 to hit roll (excludes FLY)",
                    id: 2,
                    conditions: [
                      {
                        data: {
                          keywords: ["FLY"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        operator: {
                          title: "Subtract",
                          key: "subtract",
                        },
                        relativeValue: 1,
                      },
                      text: "Modify (relative)",
                      type: "modifyRelative",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Weapon with ANTI AIR:LEGACY",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Arc pistol x10",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Arc pistol",
                type: "Ranged",
                attacks: 1,
                strength: 5,
                ap: -2,
                damage: 1,
                abilities: [
                  {
                    description: "Set damage to 2 (VEHICLE only)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: 2,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (VEHICLE only)",
                    id: 2,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 3,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Arc pistol",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: Arc rifle x10",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Arc rifle",
                type: "Ranged",
                attacks: 1,
                strength: 6,
                ap: -2,
                damage: "D3",
                abilities: [
                  {
                    description: "Set damage to 3 (VEHICLE only)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: 3,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (VEHICLE only)",
                    id: 2,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 3,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 4,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Arc rifle",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: Weapon ability: Always hit",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Weapon with ability",
                type: "Ranged",
                attacks: "D6",
                strength: 4,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    description: "Always hit",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Hit",
                          key: "hit",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Torrent",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 1,
                bs: null,
                ws: null,
                nameUnique: "Weapon with ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: Weapon ability: Blast",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Ranged weapon with Blast",
                type: "Ranged",
                attacks: "D3+3",
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Blast",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        option: {
                          title: "Blast",
                          key: "blast",
                        },
                      },
                      text: "Special",
                      type: "special",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Blast",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 1,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Blast",
              },
              {
                name: "Melee weapon with Blast",
                type: "Melee",
                attacks: "D3+3",
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Blast",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        option: {
                          title: "Blast",
                          key: "blast",
                        },
                      },
                      text: "Special",
                      type: "special",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Blast",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 1,
                id: 2,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon with Blast",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [2],
      ranged: [1],
    },
  },
  {
    name: "A: Weapon ability: Grav",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Melee weapon with Grav: D3",
                type: "Melee",
                attacks: 25,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to D3",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "D3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 1,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon with Grav: D3",
              },
              {
                name: "Ranged weapon with Grav: D3",
                type: "Ranged",
                attacks: 5,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to D3",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "D3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Grav: D3",
              },
              {
                name: "Ranged weapon with Grav: 2",
                type: "Ranged",
                attacks: 5,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to 2",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "2",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 3,
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Grav: 2",
              },
              {
                name: "Ranged weapon with Grav: 3",
                type: "Ranged",
                attacks: 5,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to 3",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 4,
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Grav: 3",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [1],
      ranged: [2, 3, 4],
    },
  },
  {
    name: "A: Weapon ability: Melta",
    abilitiesSelected: [],
    abilityIdNext: 1,
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      points: null,
      roles: ["Attacker: Ranged"],
      wounds: null,
      toughness: 0,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Ranged weapon with MELTA 2",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 8,
                ap: -4,
                damage: "D6",
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Melta 2",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "+2 to damage (within half range)",
                    effect: {
                      text: "Modify (relative)",
                      type: "modifyRelative",
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: 2,
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 1,
                ws: null,
                nameUnique: "Ranged weapon with MELTA 2",
              },
              {
                name: "Ranged weapon with MELTA D3",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 8,
                ap: -4,
                damage: "D6",
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Melta D3",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "+D3 to damage (within half range)",
                    effect: {
                      text: "Modify (relative)",
                      type: "modifyRelative",
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: "D3",
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 2,
                ws: null,
                nameUnique: "Ranged weapon with MELTA D3",
              },
            ],
            wounds: null,
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: POISON",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Legacy POISON weapon with strength '*'",
                type: "Ranged",
                attacks: 1,
                strength: "*",
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Legacy POISON weapon with strength '*'",
              },
              {
                name: "Baseline with POISON:4",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 3,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with POISON:4",
              },
              {
                name: "Baseline with POISON:3",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 3+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "3",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 4,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with POISON:3",
              },
              {
                name: "Baseline with POISON:2",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 2+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "2",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 5,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with POISON:2",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 6,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2, 3, 4, 5],
    },
  },
  {
    name: "A: Weapon ability: PLAGUE WEAPON",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 10,
                strength: 6,
                ap: -2,
                damage: 1,
                countPerUnit: 4,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with PLAGUE WEAPON",
                type: "Ranged",
                attacks: 10,
                strength: 6,
                ap: -2,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll all wound roll results of 1",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: 1,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 4,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with PLAGUE WEAPON",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=AP:-3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set AP to -3",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "AP",
                          key: "ap",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: -3,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 20,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=AP:-4",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set AP to -4",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "AP",
                          key: "ap",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: -4,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 20,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=AP:-6",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set AP to -6",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "AP",
                          key: "ap",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: -6,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 20,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=+1MW",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with WOUND:6+=+1MW",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : 1 mortal wound",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: 1,
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6+=+1MW",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: HIT:6=+2HITS",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                countPerUnit: 5,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Hit roll of 6 (unmodified) : 2 extra hits",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: "2",
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: HIT:6+=+2HITS",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                countPerUnit: 5,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Hit roll of 6+ : 2 extra hits",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: "2",
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 5,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6=DAMAGE:2",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with WOUND:6=DAMAGE:2",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : set damage to 2",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "2",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 20,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6=DAMAGE:2",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=DAMAGE:N",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 2,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with WOUND:6+=DAMAGE:2",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set damage to 2",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "2",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 2,
                ws: null,
                nameUnique: "Baseline with WOUND:6+=DAMAGE:2",
              },
              {
                name: "Baseline with WOUND:6+=DAMAGE:3",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set damage to 3",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 3,
                bs: 2,
                ws: null,
                nameUnique: "Baseline with WOUND:6+=DAMAGE:3",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 4,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2, 3],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6=+1MW",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with WOUND:6=+1MW",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : 1 mortal wound",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: 1,
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6=+1MW",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6=+2MW",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with WOUND:6=+2MW",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : 2 mortal wounds",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: "2",
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6=+2MW",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon type: Rapid Fire",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolt rifle",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: -1,
                damage: 1,
                countPerUnit: 5,
                id: 1,
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                nameUnique: "Bolt rifle",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: 9 flechette carbines @ BS3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 19,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 1,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Close combat weapon",
              },
              {
                name: "Flechette carbine",
                type: "Ranged",
                attacks: 5,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 9,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Flechette carbine",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [1],
      ranged: [2],
    },
  },
  {
    name: "A: 10 3 damage guns @ BS3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Damage 3 gun",
                type: "Ranged",
                attacks: 2,
                strength: 6,
                ap: -3,
                damage: 3,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Damage 3 gun",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: Weapon ability: DENY INVULN",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with DENY INVULN",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Disable invuln",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        option: {
                          title: "Disable invuln",
                          key: "disableInvuln",
                        },
                      },
                      text: "Disable ability",
                      type: "disableAbility",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with DENY INVULN",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: MWs on wounds of 6",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "High volume - no MWs",
                type: "Ranged",
                attacks: 6,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "High volume - no MWs",
              },
              {
                name: "High volume - MWs on 6s",
                type: "Ranged",
                attacks: 6,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : 1 mortal wound",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: 1,
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "High volume - MWs on 6s",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: HIT:6=AUTO WOUND",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 6,
            modelCount: 10,
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with HIT:6=AUTO WOUND",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Always wound on a hit roll of 6 (unmodified)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with HIT:6=AUTO WOUND",
              },
            ],
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Weapon ability: HIT:6=AUTO WOUND:!VEHICLE",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 6,
            modelCount: 10,
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Baseline with HIT:6=AUTO WOUND:!VEHICLE",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a hit roll of 6 (unmodified) (excludes VEHICLE)",
                    id: 1,
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                countPerUnit: 10,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Baseline with HIT:6=AUTO WOUND:!VEHICLE",
              },
            ],
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Melee - taser weapons",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Taser goad",
                type: "Melee",
                attacks: 11,
                strength: 6,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    description: "Hit roll of 6 (unmodified) : 2 extra hits",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: "2",
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    id: 1,
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 1,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Taser goad",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [1],
      ranged: [],
    },
  },
  {
    name: "A: Laser chickens x3",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Twin cognis lascannon",
                countPerUnit: "3",
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Twin cognis lascannon",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "D: T3, W2, 4+, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 20,
            save: 4,
            toughness: 3,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 4+, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      points: 160,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 20,
            save: 4,
            toughness: 4,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: 160,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 4+, 4++, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 4,
            modelCount: 20,
            save: 4,
            toughness: 4,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 3+, 5++, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 5,
            modelCount: 20,
            save: 3,
            toughness: 4,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 4+, 5+++, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            ignore: 5,
            modelCount: 20,
            save: 4,
            toughness: 4,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 3+, x10",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 10,
            save: 3,
            toughness: 4,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 2+, x10",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 10,
            save: 2,
            toughness: 4,
            wounds: 2,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 3+, x5",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 5,
      roles: ["Defender"],
      wounds: 3,
      toughness: 5,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 5,
            save: 3,
            toughness: 5,
            wounds: 3,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T3, W1, 4+, x5",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 5,
      roles: ["Defender"],
      wounds: 1,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 5,
            save: 4,
            toughness: 3,
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Light vehicle",
    abilityIdNext: 1,
    computed: {
      keywords: ["VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 6,
      toughness: 6,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            keywords: ["VEHICLE"],
            modelCount: 1,
            save: 4,
            toughness: 6,
            wounds: 6,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Monster equivalent of light vehicle",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 6,
      toughness: 6,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 4,
            toughness: 6,
            wounds: 6,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Regular vehicle",
    abilityIdNext: 1,
    computed: {
      keywords: ["VEHICLE"],
      leaders: [],
      modelCount: 1,
      points: 160,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            keywords: ["VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: 160,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Monster equivalent of regular vehicle",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Vehicle with FLY",
    abilityIdNext: 1,
    computed: {
      keywords: ["FLY", "VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            keywords: ["FLY", "VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Vehicle with AIRCRAFT",
    abilityIdNext: 1,
    computed: {
      keywords: ["AIRCRAFT", "FLY", "VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            keywords: ["AIRCRAFT", "FLY", "VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T3, W1, 6+, 5++, 5+++ x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 1,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            ignore: 5,
            invuln: 5,
            modelCount: 20,
            save: 6,
            toughness: 3,
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T3, W3, 6+, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 3,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 20,
            save: 6,
            toughness: 3,
            wounds: 3,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W1, 6+, 3++, x20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 1,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 3,
            modelCount: 20,
            save: 6,
            toughness: 4,
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Melee WS2 various hit re-rolls",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Sword (hits rr all possible failures)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed hit rolls",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 1,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr all possible failures)",
              },
              {
                name: "Sword (hits rr 1-2)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all hit roll results of 1–2",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 2],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 2,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr 1-2)",
              },
              {
                name: "Sword (hits rr all failed)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed hit rolls",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 3,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr all failed)",
              },
              {
                name: "Sword (hits rr 1s)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all hit roll results of 1",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: 1,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 4,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr 1s)",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [1, 2, 3, 4],
      ranged: [],
    },
  },
  {
    name: "A: Melee WS2 various wound re-rolls",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Sword (wounds rr all possible failures)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed wound rolls",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Twin-linked",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 1,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr all possible failures)",
              },
              {
                name: "Sword (wounds rr 1-2)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all wound roll results of 1–2",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 2],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 2,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr 1-2)",
              },
              {
                name: "Sword (wounds rr all failed)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed wound rolls",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 3,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr all failed)",
              },
              {
                name: "Sword (wounds rr 1s)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all wound roll results of 1",
                    scope: ["weapon"],
                    id: 1,
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: 1,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 4,
                abilityIdNext: 2,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr 1s)",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [1, 2, 3, 4],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability D3 MWs on 6s to wound",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Weapon with ability",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 20,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : D3 mortal wounds",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: "D3",
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    id: 1,
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 1,
                abilityIdNext: 2,
                bs: 2,
                ws: null,
                nameUnique: "Weapon with ability",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "A: Melee WS4 S4 A20",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                countPerUnit: 10,
                bs: null,
                ws: 4,
                nameUnique: "Close combat weapon",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [1],
      ranged: [],
    },
  },
  {
    name: "D: T8, W14, 3+, x1",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 14,
      toughness: 8,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 3,
            toughness: 8,
            wounds: 14,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Strength characteristic test",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "S7 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 7,
                ap: -3,
                damage: 2,
                id: 1,
                bs: 2,
                ws: null,
                nameUnique: "S7 gun",
              },
              {
                name: "S8 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 8,
                ap: -3,
                damage: 2,
                id: 2,
                bs: 2,
                ws: null,
                nameUnique: "S8 gun",
              },
              {
                name: "S User gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 7,
                ap: -3,
                damage: 2,
                id: 3,
                bs: 2,
                ws: null,
                nameUnique: "S User gun",
              },
              {
                name: "S +1 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 8,
                ap: -3,
                damage: 2,
                id: 4,
                bs: 2,
                ws: null,
                nameUnique: "S +1 gun",
              },
              {
                name: "Axe S User",
                type: "Melee",
                attacks: 12,
                strength: 7,
                ap: -3,
                damage: 2,
                id: 5,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Axe S User",
              },
              {
                name: "Axe S +1",
                type: "Melee",
                attacks: 12,
                strength: 8,
                ap: -3,
                damage: 2,
                id: 6,
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Axe S +1",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 7,
    weaponsSelected: {
      melee: [5, 6],
      ranged: [1, 2, 3, 4],
    },
  },
  {
    name: "A: AP to 0 characteristic test",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "AP 0 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 2,
                ws: null,
                nameUnique: "AP 0 gun",
              },
              {
                name: "AP -1 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: -1,
                damage: 1,
                id: 2,
                bs: 2,
                ws: null,
                nameUnique: "AP -1 gun",
              },
              {
                name: "AP -2 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: -2,
                damage: 1,
                id: 3,
                bs: 2,
                ws: null,
                nameUnique: "AP -2 gun",
              },
              {
                name: "AP -3 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: -3,
                damage: 1,
                id: 4,
                bs: 2,
                ws: null,
                nameUnique: "AP -3 gun",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2, 3, 4],
    },
  },
  {
    name: "A: Damage characteristic test",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Damage 1 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 6,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 2,
                ws: null,
                nameUnique: "Damage 1 gun",
              },
              {
                name: "Damage 2 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 6,
                ap: 0,
                damage: 2,
                id: 2,
                bs: 2,
                ws: null,
                nameUnique: "Damage 2 gun",
              },
              {
                name: "Damage D3 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 6,
                ap: 0,
                damage: "D3",
                id: 3,
                bs: 2,
                ws: null,
                nameUnique: "Damage D3 gun",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 4,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2, 3],
    },
  },
  {
    name: "A: Bolt pistol, BS2",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolt pistol",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 2,
                ws: null,
                nameUnique: "Bolt pistol",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "D: T3, W1, 7+, x10",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 10,
      points: 150,
      roles: ["Defender"],
      wounds: 1,
      toughness: 3,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 10,
            save: 7,
            toughness: 3,
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: 150,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "Modify (absolute) profile abilities",
    abilityIdNext: 4,
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [
          {
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "Set BS to 3 (if attacker)",
            scope: ["profile"],
            id: 1,
            effect: {
              data: {
                application: {
                  title: "BS",
                  key: "bs",
                },
                operator: {
                  title: "Replace",
                  key: "replace",
                },
                absoluteValue: "3",
              },
              text: "Modify (absolute)",
              type: "modifyAbsolute",
            },
            aliases: [],
            aliasActive: true,
            sharedWithAllUnits: false,
          },
          {
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "Set WS to 3 (if attacker)",
            scope: ["profile"],
            id: 2,
            effect: {
              data: {
                application: {
                  title: "WS",
                  key: "ws",
                },
                operator: {
                  title: "Replace",
                  key: "replace",
                },
                absoluteValue: "3",
              },
              text: "Modify (absolute)",
              type: "modifyAbsolute",
            },
            aliases: [],
            aliasActive: true,
            sharedWithAllUnits: false,
          },
          {
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "Set save roll to 2 (if defender)",
            scope: ["profile"],
            id: 3,
            effect: {
              data: {
                application: {
                  title: "Save roll",
                  key: "saveRoll",
                },
                operator: {
                  title: "Replace",
                  key: "replace",
                },
                absoluteValue: "2",
              },
              text: "Modify (absolute)",
              type: "modifyAbsolute",
            },
            aliases: [],
            aliasActive: true,
            sharedWithAllUnits: false,
          },
        ],
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 3,
            toughness: 4,
            weapons: [
              {
                name: "Bolter",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 4,
                ws: null,
                nameUnique: "Bolter",
              },
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 2,
                countPerUnit: 1,
                bs: null,
                ws: 4,
                nameUnique: "Close combat weapon",
              },
            ],
            wounds: 1,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [2],
      ranged: [1],
    },
  },
  {
    name: "A: Bolter & CC weapon (same profiles)",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      modelCount: 4,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      wounds: 4,
      toughness: 4,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            modelCount: 4,
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Bolter",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Bolter",
              },
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 2,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Close combat weapon",
              },
            ],
            wounds: 4,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [2],
      ranged: [1],
    },
  },
  {
    name: "A: Squat guns",
    abilitiesSelected: [],
    abilityIdNext: 1,
    computed: {
      keywords: [],
      leaders: [],
      modelCount: 0,
      roles: ["Attacker: Ranged"],
      toughness: 0,
      wounds: 0,
      points: 0,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: "A: Squat guns",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            name: null,
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Magna-rail baseline",
                countPerUnit: "4",
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 9,
                ap: -4,
                damage: 5,
                id: 1,
                nameUnique: "Magna-rail baseline",
              },
              {
                name: "Magna-rail",
                countPerUnit: "4",
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 9,
                ap: -4,
                damage: 5,
                abilities: [
                  {
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description: "Critical wound : disable save (inc. invuln)",
                    effect: {
                      text: "Disable mechanic",
                      type: "disableAbility",
                      data: {
                        option: {
                          title: "Disable save (inc. invuln)",
                          key: "disableSave",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    aliases: [
                      {
                        name: "Devastating wounds",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 2,
                nameUnique: "Magna-rail",
              },
              {
                name: "Conversion Beamer",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 3,
                strength: 8,
                ap: -3,
                damage: 4,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Successful roll",
                            key: "successfulRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: null,
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                      {
                        data: {
                          range: {
                            title: "Over half range",
                            key: "overHalfRange",
                          },
                        },
                        preselected: false,
                        text: "Range",
                        type: "range",
                        required: false,
                      },
                    ],
                    description:
                      "Successful hit roll : 1 extra hit (over half range)",
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: 1,
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    id: 1,
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 3,
                bs: 3,
                ws: null,
                nameUnique: "Conversion Beamer",
              },
            ],
            wounds: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponIdNext: 4,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Knight",
    abilityIdNext: 1,
    computed: {
      keywords: ["TITANIC", "VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 24,
      toughness: 8,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 5,
            keywords: ["TITANIC", "VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 8,
            wounds: 24,
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability: Single-use hit re-rolls",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "With Hit roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed hit roll",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "With Hit roll : re-roll one failed",
              },
              {
                name: "With x2 Hit roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed hit roll",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "Re-roll one possible failed hit roll",
                    scope: ["weapon"],
                    id: 2,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 3,
                id: 3,
                bs: 3,
                ws: null,
                nameUnique: "With x2 Hit roll : re-roll one failed",
              },
              {
                name: "With x3 Hit roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed hit roll",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "Re-roll one possible failed hit roll",
                    scope: ["weapon"],
                    id: 2,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "Re-roll one possible failed hit roll",
                    scope: ["weapon"],
                    id: 3,
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 4,
                id: 4,
                bs: 3,
                ws: null,
                nameUnique: "With x3 Hit roll : re-roll one failed",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability: Single-use wound re-rolls",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "With Wound roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed wound roll",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "With Wound roll : re-roll one failed",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability: Single-use damage re-rolls",
    abilityIdNext: 1,
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        id: 1,
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                id: 1,
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
              },
              {
                name: "Damage roll : re-roll one result of 1",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                abilities: [
                  {
                    description: "Re-roll one damage result of 1",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: "1",
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 2,
                bs: 3,
                ws: null,
                nameUnique: "Damage roll : re-roll one result of 1",
              },
              {
                name: "Damage roll : re-roll one result of 1-3",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                abilities: [
                  {
                    description: "Re-roll one damage result of 1–3",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 3],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 3,
                bs: 3,
                ws: null,
                nameUnique: "Damage roll : re-roll one result of 1-3",
              },
              {
                name: "Damage roll : re-roll one result of 1-4",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                abilities: [
                  {
                    description: "Re-roll one damage result of 1–4",
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 4],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 4,
                bs: 3,
                ws: null,
                nameUnique: "Damage roll : re-roll one result of 1-4",
              },
            ],
            id: 1,
            name: null,
          },
        ],
        name: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T7, W3, 3+, INFANTRY, x6",
    abilitiesSelected: [],
    abilityIdNext: 1,
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      modelCount: 6,
      points: null,
      roles: ["Defender"],
      wounds: 3,
      toughness: 7,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            bs: null,
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 6,
            save: 3,
            toughness: 7,
            weapons: [],
            wounds: 3,
            ws: null,
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Psychic",
    abilitiesSelected: [],
    abilityIdNext: 1,
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      points: null,
      roles: ["Attacker: Ranged"],
      wounds: null,
      toughness: 0,
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Mind gun",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 20,
                bs: 3,
                strength: 6,
                ap: -2,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Psychic",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [],
                    description: "Psychic",
                    effect: {
                      text: "Special",
                      type: "special",
                      data: {
                        option: {
                          title: "Psychic",
                          key: "psychic",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 1,
                nameUnique: "Mind gun",
              },
              {
                name: "Baseline",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 20,
                bs: 3,
                strength: 6,
                ap: -2,
                damage: 1,
                id: 2,
                nameUnique: "Baseline",
              },
            ],
            wounds: null,
            id: 1,
            name: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "A: Lethal hits, +1 to wound & Re-roll wounds with TORRENT & non-TORRENT weapons",
    abilitiesSelected: [2, 3],
    abilityIdNext: 4,
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      points: null,
      roles: ["Attacker: Ranged"],
      wounds: null,
      toughness: 0,
    },
    modelTypeIdNext: 2,
    orderSlain: [
      {
        modelTypeId: 1,
        unitId: 1,
      },
    ],
    unitIdNext: 2,
    units: [
      {
        abilities: [
          {
            id: 1,
            scope: ["profile"],
            effect: {
              data: {
                crit: false,
                type: {
                  key: "always",
                  title: "Always",
                },
                outcome: {
                  key: "wound",
                  title: "Wound",
                },
                irrespective: false,
              },
              text: "Override requirements",
              type: "overrideReqs",
            },
            aliases: [
              {
                name: "Lethal hits",
                type: "reserved",
              },
            ],
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                text: "Profile role",
                type: "profileRole",
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                data: {
                  crit: {
                    key: "hitRoll",
                    title: "Critical hit",
                  },
                },
                text: "Critical hit/wound",
                type: "crit",
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            aliasActive: true,
            description: "Critical hit : always wound (if attacker)",
            sharedWithAllUnits: false,
          },
          {
            id: 2,
            scope: ["profile"],
            effect: {
              data: {
                operator: {
                  key: "add",
                  title: "Add",
                },
                application: {
                  key: "woundRoll",
                  title: "Wound roll",
                },
                relativeValue: "1",
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliases: [],
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                text: "Profile role",
                type: "profileRole",
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
            ],
            aliasActive: true,
            description: "+1 to wound roll (if attacker)",
            sharedWithAllUnits: false,
          },
          {
            id: 3,
            scope: ["profile"],
            effect: {
              data: {
                limit: {
                  key: "none",
                  title: "No limit",
                  dataCySuffix: "none",
                },
                valueRange: [],
                application: {
                  key: "woundRoll",
                  title: "Wound roll",
                },
                valueSingle: null,
                resultToReRoll: {
                  key: "allPossibleFailures",
                  title: "All possible failures",
                  applications: [
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "all-possible-failures",
                },
              },
              text: "Re-roll",
              type: "reRoll",
            },
            aliases: [],
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                text: "Profile role",
                type: "profileRole",
                scope: ["global", "profile", "weapon"],
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
            ],
            aliasActive: true,
            description:
              "Re-roll all possible failed wound rolls (if attacker)",
            sharedWithAllUnits: false,
          },
        ],
        canBeLed: false,
        canLead: false,
        id: 1,
        modelTypes: [
          {
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            name: "A: Lethal hits, +1 to wound & Re-roll wounds with TORRENT & non-TORRENT weapons",
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Not TORRENT weapon",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 5,
                bs: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 1,
                nameUnique: "Not TORRENT weapon",
              },
              {
                name: "TORRENT weapon",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 4,
                bs: "",
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    id: 1,
                    scope: ["weapon"],
                    effect: {
                      data: {
                        crit: false,
                        type: {
                          key: "always",
                          title: "Always",
                        },
                        outcome: {
                          key: "hit",
                          title: "Hit",
                        },
                        irrespective: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [
                      {
                        name: "Torrent",
                        type: "reserved",
                      },
                    ],
                    conditions: [],
                    aliasActive: true,
                    description: "Always hit",
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 3,
                id: 2,
                nameUnique: "TORRENT weapon",
              },
            ],
            wounds: null,
            id: 1,
          },
        ],
        points: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
  {
    name: "Boys led by Medic",
    abilitiesSelected: [],
    abilityIdNext: 1,
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      leaders: [
        {
          unitId: 1,
          unitName: "Medic",
        },
      ],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      modelCount: 11,
      points: 155,
      wounds: 14,
      toughness: 5,
    },
    modelTypeIdNext: 4,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 2,
          modelTypeName: "Boys",
          unitId: 2,
        },
        {
          modelTypeId: 3,
          modelTypeName: "Boss Knob",
          unitId: 2,
        },
        {
          modelTypeId: 1,
          modelTypeName: "Medic",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 3,
    units: [
      {
        abilities: [],
        id: 1,
        leader: true,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: null,
            keywords: ["CHARACTER", "INFANTRY"],
            modelCount: 1,
            name: "Medic",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Syringe",
                countPerUnit: 1,
                type: "Melee",
                attacks: 1,
                ws: 3,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                      {
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        description: "Exclude one or more keywords.",
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description:
                      "Critical wound : D6 mortal wounds (excludes VEHICLE)",
                    effect: {
                      text: "Mortal wounds",
                      type: "mortalWounds",
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        matchWeaponDamage: false,
                        mortalWoundsValue: "D6",
                      },
                    },
                    id: 2,
                    scope: ["weapon"],
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 3,
                id: 1,
                nameUnique: "Syringe",
              },
              {
                name: "Power claw",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 4,
                strength: 9,
                ap: -2,
                damage: 2,
                id: 2,
                nameUnique: "Power claw",
              },
            ],
            wounds: 3,
          },
        ],
        name: "Medic",
        points: 70,
      },
      {
        abilities: [],
        id: 2,
        leader: false,
        modelTypes: [
          {
            id: 2,
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 9,
            name: "Boys",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Chopper",
                countPerUnit: 9,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                id: 3,
                nameUnique: "Chopper",
              },
              {
                name: "Slugger",
                countPerUnit: 9,
                type: "Ranged",
                attacks: 1,
                bs: 5,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 4,
                nameUnique: "Slugger (Boys)",
              },
            ],
            wounds: 1,
          },
          {
            id: 3,
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Boss Knob",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Big chopper",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                id: 5,
                nameUnique: "Big chopper",
              },
              {
                name: "Slugger",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 5,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 6,
                nameUnique: "Slugger (Boss Knob)",
              },
            ],
            wounds: 2,
          },
        ],
        name: "Boys",
        points: 85,
      },
    ],
    weaponIdNext: 7,
    weaponsSelected: {
      melee: [1, 2, 3, 5],
      ranged: [4, 6],
    },
  },
  {
    name: "Avengers",
    abilitiesSelected: [],
    abilityIdNext: 1,
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 5,
      wounds: 6,
      toughness: 3,
    },
    modelTypeIdNext: 3,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: "Avenger",
          unitId: 1,
        },
        {
          modelTypeId: 2,
          modelTypeName: "Avenger Exarch",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 4,
            name: "Avenger",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Close combat weapon",
                countPerUnit: 4,
                type: "Melee",
                attacks: 2,
                ws: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 1,
                nameUnique: "Close combat weapon",
              },
              {
                name: "Catapult",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 3,
                bs: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Lethal hits",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical hit",
                            key: "hitRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                      },
                    ],
                    description: "Critical hit : always wound",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 2,
                nameUnique: "Catapult",
              },
            ],
            wounds: 1,
          },
          {
            id: 2,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Avenger Exarch",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Glaive",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 5,
                ap: -2,
                damage: 1,
                id: 3,
                nameUnique: "Glaive",
              },
              {
                name: "Pistol",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                id: 4,
                nameUnique: "Pistol",
              },
            ],
            wounds: 2,
          },
        ],
        name: "Avengers",
        points: null,
      },
    ],
    weaponIdNext: 5,
    weaponsSelected: {
      melee: [1, 3],
      ranged: [2, 4],
    },
  },
  {
    name: "Vanguard led by Manipulus",
    abilitiesSelected: [3, 1],
    abilityIdNext: 4,
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      leaders: [
        {
          unitId: 2,
          unitName: "Manipulus",
        },
      ],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 11,
      wounds: 14,
      toughness: 3,
    },
    modelTypeIdNext: 4,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 3,
          modelTypeName: "Manipulus",
          unitId: 2,
        },
        {
          modelTypeId: 1,
          modelTypeName: "Skitarii Vanguard",
          unitId: 1,
        },
        {
          modelTypeId: 2,
          modelTypeName: "Skitarii Vanguard Alpha",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 3,
    units: [
      {
        abilities: [
          {
            aliases: [
              {
                name: "Ignores cover",
                type: "reserved",
              },
            ],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
            ],
            description: "Ignore cover (if attacker)",
            effect: {
              text: "Special",
              type: "special",
              data: {
                option: {
                  title: "Ignore cover",
                  key: "ignoreCover",
                },
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: true,
            id: 3,
          },
        ],
        id: 1,
        leader: false,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 9,
            name: "Skitarii Vanguard",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Radium carbine",
                countPerUnit: 9,
                type: "Ranged",
                attacks: 3,
                bs: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 1,
                nameUnique: "Radium carbine",
              },
              {
                name: "Close combat weapon",
                countPerUnit: 9,
                type: "Melee",
                attacks: 1,
                ws: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 2,
                nameUnique: "Close combat weapon",
              },
            ],
            wounds: 1,
          },
          {
            id: 2,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Skitarii Vanguard Alpha",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Alpha combat weapon",
                countPerUnit: 1,
                type: "Melee",
                attacks: 2,
                ws: 4,
                strength: 5,
                ap: -1,
                damage: 1,
                id: 3,
                nameUnique: "Alpha combat weapon",
              },
              {
                name: "Radium carbine",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 3,
                bs: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 4,
                nameUnique: "Radium carbine",
              },
            ],
            wounds: 1,
          },
        ],
        name: "Skitarii Vanguard",
        points: null,
      },
      {
        abilities: [
          {
            aliases: [
              {
                name: "Lethal hits",
                type: "reserved",
              },
            ],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                text: "Critical hit/wound",
                type: "crit",
                data: {
                  crit: {
                    title: "Critical hit",
                    key: "hitRoll",
                  },
                },
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            description: "Critical hit : always wound (if attacker)",
            effect: {
              text: "Override requirements",
              type: "overrideReqs",
              data: {
                crit: false,
                irrespective: false,
                outcome: {
                  title: "Wound",
                  key: "wound",
                },
                type: {
                  title: "Always",
                  key: "always",
                },
              },
            },
            scope: ["profile"],
            id: 1,
            sharedWithAllUnits: false,
          },
          {
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "4++ invuln save",
            aliases: [],
            effect: {
              data: {
                invulnValue: "4",
              },
              text: "Invulnerable save",
              type: "invulnSave",
            },
            scope: ["profile"],
            aliasActive: true,
            id: 2,
            sharedWithAllUnits: false,
          },
        ],
        id: 2,
        leader: true,
        modelTypes: [
          {
            id: 3,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY", "CHARACTER"],
            modelCount: 1,
            name: "Manipulus",
            save: 2,
            toughness: 4,
            weapons: [
              {
                name: "Omnissian staff",
                countPerUnit: 1,
                type: "Melee",
                attacks: 4,
                ws: 3,
                strength: 6,
                ap: -1,
                damage: 2,
                id: 5,
                nameUnique: "Omnissian staff",
              },
              {
                name: "Transonic cannon",
                countPerUnit: 1,
                type: "Ranged",
                attacks: "D6",
                strength: 4,
                ap: 0,
                damage: 2,
                abilities: [
                  {
                    description: "Always hit",
                    scope: ["weapon"],
                    conditions: [],
                    aliases: [
                      {
                        name: "Torrent",
                        type: "reserved",
                      },
                    ],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Hit",
                          key: "hit",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliasActive: true,
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [
                      {
                        name: "Devastating wounds",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description: "Critical wound : disable save (inc. invuln)",
                    effect: {
                      text: "Disable mechanic",
                      type: "disableAbility",
                      data: {
                        option: {
                          title: "Disable save (inc. invuln)",
                          key: "disableSave",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 2,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 3,
                id: 6,
                nameUnique: "Transonic cannon",
              },
            ],
            wounds: 4,
          },
        ],
        name: "Manipulus",
        points: null,
      },
    ],
    weaponIdNext: 7,
    weaponsSelected: {
      melee: [2, 3, 5],
      ranged: [1, 4, 6],
    },
  },
  {
    name: "Breachers led by Dominus",
    abilitiesSelected: [3],
    abilityIdNext: 4,
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      leaders: [
        {
          unitId: 2,
          unitName: "Dominus",
        },
      ],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 4,
      wounds: 13,
      toughness: 7,
    },
    modelTypeIdNext: 3,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: "Breacher",
          unitId: 1,
        },
        {
          modelTypeId: 2,
          modelTypeName: "Dominus",
          unitId: 2,
        },
      ],
    },
    unitIdNext: 3,
    units: [
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Re-roll all hit roll results of 1 (if attacker)",
            effect: {
              text: "Re-roll",
              type: "reRoll",
              data: {
                application: {
                  title: "Hit roll",
                  key: "hitRoll",
                },
                limit: {
                  title: "No limit",
                  key: "none",
                  dataCySuffix: "none",
                },
                resultToReRoll: {
                  title: "Single result",
                  key: "single",
                  applications: [
                    "damage",
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "single",
                },
                valueRange: [],
                valueSingle: "1",
              },
            },
            scope: ["profile"],
            id: 1,
            sharedWithAllUnits: false,
          },
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Re-roll all possible failed hit rolls (if attacker)",
            effect: {
              text: "Re-roll",
              type: "reRoll",
              data: {
                application: {
                  title: "Hit roll",
                  key: "hitRoll",
                },
                limit: {
                  title: "No limit",
                  key: "none",
                  dataCySuffix: "none",
                },
                resultToReRoll: {
                  title: "All possible failures",
                  key: "allPossibleFailures",
                  applications: [
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "all-possible-failures",
                },
                valueRange: [],
                valueSingle: null,
              },
            },
            scope: ["profile"],
            id: 2,
            sharedWithAllUnits: true,
          },
        ],
        id: 1,
        leader: false,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: 6,
            keywords: ["INFANTRY"],
            modelCount: 3,
            name: "Breacher",
            save: 3,
            toughness: 7,
            weapons: [
              {
                name: "Hydraulic claw",
                countPerUnit: 1,
                type: "Melee",
                attacks: 2,
                ws: 4,
                strength: 8,
                ap: -2,
                damage: 3,
                id: 1,
                nameUnique: "Hydraulic claw",
              },
              {
                name: "Heavy arc rifle",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                bs: 4,
                strength: 8,
                ap: -2,
                damage: 3,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-VEHICLE 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (VEHICLE only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [
                      {
                        name: "Rapid fire 2",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 2 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: "2",
                        applyToExtraAttacks: false,
                      },
                    },
                    scope: ["weapon"],
                    id: 2,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 3,
                id: 2,
                nameUnique: "Heavy arc rifle",
              },
            ],
            wounds: 3,
          },
        ],
        name: "Breachers",
        points: null,
      },
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Defender",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Feel no pain 5+",
            effect: {
              text: "Feel no pain",
              type: "feelNoPain",
              data: {
                value: "5",
              },
            },
            scope: ["profile"],
            id: 3,
            sharedWithAllUnits: true,
          },
        ],
        id: 2,
        leader: true,
        modelTypes: [
          {
            id: 2,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY", "CHARACTER"],
            modelCount: 1,
            name: "Dominus",
            save: 2,
            toughness: 4,
            weapons: [
              {
                name: "Omnissian axe",
                countPerUnit: 1,
                type: "Melee",
                attacks: 4,
                ws: 3,
                strength: 6,
                ap: -2,
                damage: 2,
                id: 3,
                nameUnique: "Omnissian axe",
              },
              {
                name: "Macrostubber",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 5,
                bs: 3,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 4,
                nameUnique: "Macrostubber",
              },
              {
                name: "Volkite blaster",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 3,
                bs: 3,
                strength: 5,
                ap: 0,
                damage: 2,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Devastating wounds",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description: "Critical wound : disable save (inc. invuln)",
                    effect: {
                      text: "Disable mechanic",
                      type: "disableAbility",
                      data: {
                        option: {
                          title: "Disable save (inc. invuln)",
                          key: "disableSave",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                abilityIdNext: 2,
                id: 5,
                nameUnique: "Volkite blaster",
              },
            ],
            wounds: 4,
          },
        ],
        name: "Dominus",
        points: null,
      },
    ],
    weaponIdNext: 6,
    weaponsSelected: {
      melee: [1, 3],
      ranged: [2, 4, 5],
    },
  },
  {
    name: "Advanced attacker with shared & unshared abilities",
    abilitiesSelected: [],
    abilityIdNext: 3,
    computed: {
      keywords: [],
      modelCount: 7,
      roles: ["Attacker: Ranged", "Defender"],
      toughness: 4,
      wounds: 16,
      points: 0,
      leaders: [
        {
          unitId: 1,
          unitName: "Leader unit",
        },
      ],
    },
    modelTypeIdNext: 3,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 2,
          modelTypeName: "Grunt model type",
          unitId: 2,
        },
        {
          modelTypeId: 1,
          modelTypeName: "Leader model type",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 3,
    units: [
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "+1 to wound roll (if attacker)",
            effect: {
              text: "Modify (relative)",
              type: "modifyRelative",
              data: {
                application: {
                  title: "Wound roll",
                  key: "woundRoll",
                },
                operator: {
                  title: "Add",
                  key: "add",
                },
                relativeValue: "1",
              },
            },
            id: 2,
            scope: ["profile"],
            sharedWithAllUnits: false,
            updated: 1719855292765,
          },
        ],
        id: 2,
        leader: false,
        modelTypes: [
          {
            id: 2,
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 6,
            name: "Grunt model type",
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Grunt gun",
                nameUnique: "Grunt gun",
                countPerUnit: 6,
                type: "Ranged",
                attacks: 2,
                bs: 4,
                strength: 4,
                ap: 0,
                damage: 2,
                updated: 1719856287678,
                id: 1,
                computed: {
                  modelTypeId: 2,
                  unitId: 2,
                },
              },
            ],
            wounds: 2,
          },
        ],
        name: "Grunt unit",
        points: null,
      },
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Re-roll all possible failed hit rolls (if attacker)",
            effect: {
              text: "Re-roll",
              type: "reRoll",
              data: {
                application: {
                  title: "Hit roll",
                  key: "hitRoll",
                },
                limit: {
                  title: "No limit",
                  key: "none",
                  dataCySuffix: "none",
                },
                resultToReRoll: {
                  title: "All possible failures",
                  key: "allPossibleFailures",
                  applications: [
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "all-possible-failures",
                },
                valueRange: [],
                valueSingle: null,
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: true,
            id: 1,
            updated: 1719855087644,
          },
        ],
        id: 1,
        leader: true,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 1,
            name: "Leader model type",
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Leader gun",
                nameUnique: "Leader gun",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 12,
                bs: 4,
                strength: 4,
                ap: 0,
                damage: 2,
                updated: 1719856295505,
                id: 2,
                computed: {
                  modelTypeId: 1,
                  unitId: 1,
                },
              },
            ],
            wounds: 4,
          },
        ],
        name: "Leader unit",
        points: null,
      },
    ],
    weaponIdNext: 3,
    weaponsSelected: {
      melee: [],
      ranged: [1, 2],
    },
  },
  {
    name: "Advanced defender with shared & unshared abilities",
    abilitiesSelected: [],
    abilityIdNext: 4,
    computed: {
      keywords: [],
      modelCount: 4,
      roles: ["Defender"],
      toughness: 4,
      wounds: 8,
      points: 0,
      leaders: [
        {
          unitId: 1,
          unitName: "Leader unit",
        },
      ],
    },
    modelTypeIdNext: 3,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 2,
          modelTypeName: "Grunt model type",
          unitId: 2,
        },
        {
          modelTypeId: 1,
          modelTypeName: "Leader model type",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 3,
    units: [
      {
        abilities: [
          {
            description: "Degrade AP by 3 (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "AP",
                  key: "ap",
                },
                operator: {
                  title: "Degrade by",
                  key: "degrade",
                },
                relativeValue: "3",
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: false,
            id: 2,
            updated: 1720109150634,
          },
        ],
        id: 2,
        leader: false,
        modelTypes: [
          {
            id: 2,
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 2,
            name: "Grunt model type",
            save: 4,
            toughness: 4,
            weapons: [],
            wounds: 2,
          },
        ],
        name: "Grunt unit",
        points: null,
      },
      {
        abilities: [
          {
            description: "-1 to damage (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "Damage",
                  key: "damage",
                },
                operator: {
                  title: "Subtract",
                  key: "subtract",
                },
                relativeValue: "1",
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: true,
            id: 1,
            updated: 1720109036837,
          },
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Defender",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Degrade AP by 4 (if defender)",
            effect: {
              text: "Modify (relative)",
              type: "modifyRelative",
              data: {
                application: {
                  title: "AP",
                  key: "ap",
                },
                operator: {
                  title: "Degrade by",
                  key: "degrade",
                },
                relativeValue: "4",
              },
            },
            id: 3,
            scope: ["profile"],
            sharedWithAllUnits: true,
            updated: 1720116997851,
          },
        ],
        id: 1,
        leader: true,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 2,
            name: "Leader model type",
            save: 4,
            toughness: 4,
            weapons: [],
            wounds: 2,
          },
        ],
        name: "Leader unit",
        points: null,
      },
    ],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "Multiple attacker abilities & multiple defender abilities",
    abilitiesSelected: [],
    abilityIdNext: 5,
    computed: {
      keywords: [],
      modelCount: 4,
      roles: ["Attacker: Ranged", "Defender"],
      toughness: 4,
      wounds: 16,
      points: 0,
      leaders: [],
    },
    modelTypeIdNext: 2,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName:
            "Multiple attacker abilities & multiple defender abilities",
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: [
          {
            description: "+1 to armour save roll (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "Armour save roll",
                  key: "armourSaveRoll",
                },
                operator: {
                  title: "Add",
                  key: "add",
                },
                relativeValue: 1,
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: false,
            id: 1,
          },
          {
            description: "-1 to damage (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "Damage",
                  key: "damage",
                },
                operator: {
                  title: "Subtract",
                  key: "subtract",
                },
                relativeValue: 1,
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: false,
            id: 2,
          },
          {
            aliases: [
              {
                name: "Lethal hits",
                type: "reserved",
              },
            ],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                text: "Critical hit/wound",
                type: "crit",
                data: {
                  crit: {
                    title: "Critical hit",
                    key: "hitRoll",
                  },
                },
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            description: "Critical hit : always wound (if attacker)",
            effect: {
              text: "Override requirements",
              type: "overrideReqs",
              data: {
                crit: false,
                irrespective: false,
                outcome: {
                  title: "Wound",
                  key: "wound",
                },
                type: {
                  title: "Always",
                  key: "always",
                },
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: false,
            id: 3,
          },
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                text: "Critical hit/wound",
                type: "crit",
                data: {
                  crit: {
                    title: "Critical wound",
                    key: "woundRoll",
                  },
                },
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            description: "Critical wound : improve AP by 1 (if attacker)",
            effect: {
              text: "Modify (relative)",
              type: "modifyRelative",
              data: {
                application: {
                  title: "AP",
                  key: "ap",
                },
                operator: {
                  title: "Improve by",
                  key: "improve",
                },
                relativeValue: "1",
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: false,
            id: 4,
          },
        ],
        id: 1,
        leader: false,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 4,
            name: null,
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Gun 1",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 4,
                bs: 4,
                strength: 4,
                ap: 0,
                damage: 1,
                updated: 1720121201818,
                id: 1,
                nameUnique: "Gun 1",
                computed: {
                  modelTypeId: 1,
                  unitId: 1,
                },
              },
            ],
            wounds: 4,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponIdNext: 2,
    weaponsSelected: {
      melee: [],
      ranged: [1],
    },
  },
]
